import React, { useState, useEffect } from 'react';
import Accordion from '../accordion';
import TermsItem from './terms-item';
import ConditionNav from '../conditions.jsx/condition-nav';
import Controls from '../controls';
import TermNav from './term-nav';
import { Link } from 'react-router-dom';

const Terms = ({
	accordionData,
	project,
	removeTerm,
	openTermNav,
	closeTermNav,
	term,
	load,
	removeCondition,
	setFilter,
    permission
}) => {
	const [termSelected, setTermSelected] = useState(0);

	function openConditionNav(term) {
		setTermSelected(term);
		const sidePanel = document.getElementById('conditionNav');
		const sidePanelContent = document.getElementById('conditionNav-content');
		if (sidePanel && sidePanelContent) {
			sidePanel.style.width = '100%';
			sidePanelContent.style.width = '600px';
		}
	}

	function closeConditionNav() {
		setTermSelected({});

		const sidePanel = document.getElementById('conditionNav');
		const sidePanelContent = document.getElementById('conditionNav-content');
		if (sidePanel && sidePanelContent) {
			sidePanel.style.width = '0';
			sidePanelContent.style.width = '0';
		}
	}
    
    return (
        <React.Fragment>
            <div className="terms">
                <div className="screen row">
                    <h3 className="weight-medium">Términos</h3>
                </div>
                <div className="white-space-24"></div>
                <Controls
                    sidePanel={permission !== 2 ? true : false}
                    btnExport={false}
                    openPanel={openTermNav.bind(this)}
                    setFilter={setFilter} />
                <div className="white-space-24"></div>
                {accordionData.length !== 0 ? <div>
                    <div className="accordion">
                        {accordionData.map((termino, i) => (
                            <Accordion 
                                title={termino.nombre}
                                content={termino.description} 
                                removeTerm={removeTerm.bind(this)} 
                                term={termino} 
                                openTermNav={openTermNav.bind(this)}
                                permission={permission}
                            >
                                <div className="content">
                                    <div>
                                        <h5 className="weight-regular text-justify">{termino.description}</h5>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="row align-center">
                                        <div className="">
                                            <h4 className="weight-medium">Condicionantes</h4>
                                        </div>
                                        {permission !== 2 && <div className="auto justify-end">
                                            <i className="fas fa-plus-square color-green cursor-pointer" onClick={openConditionNav.bind(this, termino)}></i>
                                            <ConditionNav
                                                edit={false}
                                                project={project}
                                                terms={accordionData}
                                                term={termSelected}
                                                condition=""
                                                conditions={termSelected.condiciones} 
                                                closeConditionNav={closeConditionNav.bind(this)}
                                                permission={permission}
                                                />
                                        </div>}
                                    </div>
                                    <div className="white-space-16"></div>
                                    {termino.condiciones.length !== 0 ?
                                        <div className="conditions-table table-responsive">
                                            <table>
                                                <thead>
                                                    <tr className="text-left">
                                                        <th>Condición</th>
                                                        <th>Descripción</th>
                                                        {permission !== 2 && <th className="justify-center">Eliminar</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {termino.condiciones.map(condition =>
                                                        <tr className="table-row text-left">
                                                            <td>
                                                                <Link className="weight-regular cursor-pointer" to={`/projects/${project.id_proyecto}/${termino.id_termino}/${condition.id_condicion}`}>
                                                                    {condition.nombre}
                                                                </Link>
                                                            </td>
                                                            <td><h5 className='truncate-ellipsis weight-regular'>{condition.description}</h5></td>
                                                            {permission !== 2 && <td className="justify-center">
                                                                <i className="fas fa-trash cursor-pointer" onClick={removeCondition.bind(this, condition.id_condicion)} />
                                                            </td>}
                                                        </tr>)}
                                                </tbody>
                                            </table>
                                        </div> :
                                        <div className="justify-center">
                                            <h5 className="weight-regular">No hay condicionantes que mostrar.</h5>
                                        </div>}
                                </div>
                            </Accordion>
                        ))}
                    </div>
                </div> :
                    <div className="justify-center">
                        <h5 className="color-black weight-regular">No hay términos para mostrar.</h5>
                    </div>}
            </div>
            <TermNav
                closeTermNav={closeTermNav.bind(this)}
                term={term}
                project={project}
                load={load.bind(this)}
            />
        </React.Fragment>
    )
}

export default Terms;
