import React, {useState, useEffect} from "react";
import swal from "sweetalert";
import Controls from "../components/controls";
import Header from "../components/header";
import Menu from "../components/menu";
import UsersTable from "../components/users/users-table";
import {URL_API} from "../core/urlsApi";
import Request from "../api";
import {Consumer} from "../context";
import UserNav from "../components/users/user-nav";

const req = new Request();

const Users = (props) => {
	const [users, setUsers] = useState([]);
	const [userUpdate, setUserUpdate] = useState([]);
	const [userId, setUserId] = useState(0);
	const [permission, setPermission] = useState(0);

	useEffect(() => {
		const getUserId = async () => {
			const { data } = await props.context?.loadUser();
			if (data) {
				const { id_usuario, tipo } = data;
				if (id_usuario) {
					setUserId(id_usuario);
					setPermission(tipo);
				}
			}
		};
		getUserId();

		getUsers();
	}, []);

	async function getUsers() {
		props.context.showLoading();
		const res = await req.get(URL_API + "/users/getAll");
		if (res) {
			if (res.users) {
				setUsers(res.users);
			}
		}
		props.context.closeLoading();
	}

	function openConditionNav(user) {
		setUserUpdate(Array.isArray(user) ? {} : user);

		const sidePanel = document.getElementById("clientsNav");
		if (sidePanel) {
			sidePanel.style.width = "100%";
			document.getElementById("clientsNav-content").style.width = "600px";
		}
	}

	function closeConditionNav() {
		setUserUpdate({});
		const sidePanel = document.getElementById("clientsNav");
		if (sidePanel) {
			document.getElementById("clientsNav").style.width = "0";
			document.getElementById("clientsNav-content").style.width = "0";
		}
	}

	async function removeUser(id) {
		swal({
			title: "¿Desea eliminar usuario?",
			text: "Una vez eliminado, los datos no podrán ser recuperados.",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then(async (willDelete) => {
			if (willDelete) {
				const res = await req.post(URL_API + "/users/remove", {id_usuario: id});
				// console.log(res);
				if (res.deleted) {
					swal("¡Listo! Usuario eliminado.", {
						icon: "success",
					});
					getUsers();
					setUserUpdate({});
				}

				if (res.error) {
					swal(res.message, {
						icon: "error",
					});
				}
			}
		});
	}

	return (
		<div className="users row justify-center">
			<Menu />
			<div className="column justify-start">
				<Header />
				<div className="users-content justify-center">
					<div className="container">
						{permission !== 2 && <div className="column">
							<div className="align-center">
								<h2 className="weight-bold">Usuarios</h2>
							</div>
							<div className="white-space-16"></div>
							<Controls sidePanel={true} openPanel={openConditionNav} />
							<div className="white-space-24"></div>
							<UsersTable users={users} openConditionNav={openConditionNav} removeUser={removeUser} />
						</div>}
					</div>
				</div>
			</div>
			<UserNav closeConditionNav={closeConditionNav} user={userUpdate} getUsers={getUsers} />
		</div>
	);
};
export default Consumer(Users);
