import React, {useState, useEffect} from "react";
import swal from "sweetalert";
import Controls from "../components/controls";
import {Consumer} from "../context";
import Header from "../components/header";
import Menu from "../components/menu";
import ProjectsTable from "../components/projects/projects-table";
import Terms from "../components/projects/terms-gral";
import {URL_API} from "../core/urlsApi";
import Request from "../api";
import ProjectsNav from "../components/projects/projects-nav";

const req = new Request();

const Projects = ({context, match}) => {
	const [projectsGral, setProjectsGralState] = useState(true);
	const [projects, setProjects] = useState([]);
	const [projectsFilter, setProjectsFilter] = useState([]);
	const [projectUpd, setProjectUpd] = useState([]);
	const [clients, setClients] = useState([]);
	const [project, setProjectDetail] = useState([]);
	const [filter, setFilter] = useState("");
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState(0);
    const [clientId, setIdClient] = useState(0);
    const [permission, setPermission] = useState(0);

	useEffect(() => {
        const getUserId = async () => {
			const {data} = await context?.loadUser();
			if (data) {
				const {id_usuario, tipo } = data;
				if (id_usuario) {
					setUserId(id_usuario);
                    setPermission(tipo);
				}
			}
		};
		getUserId();
        getClients();
        getUsers();
	}, []);

    useEffect(() => {
        if (userId != 0) {
            load();
        }
    }, [userId]);

    useEffect(() => {
        let proyectos = projects.filter(cli => {
            if (cli.nombre.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                return true;
            } else if (cli.n_cliente.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                return true;
            } else {
                return false;
            }
        })

        setProjectsFilter(proyectos);
    }, [filter]);

    async function load() {
        let id_client = match.params.id_client;
        if (id_client) {
            id_client = parseInt(id_client);
            setIdClient(id_client);

            getAllProjects(id_client, userId);
        }
    }

    async function getAllProjects(idClient, idUsuario) {
        context.showLoading();
        const res = await req.post(URL_API + "/projects/getAll",{ id_usuario: idUsuario, id_client: idClient });
        if (res) {
            if (res.proyectos) {
                setProjects(res.proyectos);
                setProjectsFilter(res.proyectos);
                context.setProjects(res.proyectos);
            }
        }
        context.closeLoading();
    }

    async function getClients() {
        const res = await req.get(URL_API + "/clients/getAll");
        if (res) {
            if (res.clients) {
                setClients(res.clients);
            }
        }
    }

    async function getUsers() {
        const res = await req.get(URL_API + "/users/getAll");
        if (res) {
            if (res.users) {
                setUsers(res.users);
            }
        }
    }

    async function removeProject(id) {
        swal({
            title: "¿Desea eliminar proyecto?",
            text: "Una vez eliminado, los datos no podrán ser recuperados.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const res = await req.post(URL_API + "/projects/remove", {id_proyecto: id});
                if (res) {
                    console.log(res);
                    if (res.deleted) {
                        swal(res.message, {
                            icon: "success",
                        });

                        closeProjectNav();

                        getAllProjects(clientId, userId);
                    }
                }

                if (res.error) {
                    swal(res.message, {
                        icon: "error",
                    });
                }
            }
        });
    }

    function openProjectNav(project) {
        setProjectUpd(project);

        const sidePanel = document.getElementById("projectsNav");
        if (sidePanel) {
            document.getElementById("projectsNav").style.width = "100%";
            document.getElementById("projectsNav-content").style.width = "600px";
        }
    }

    function closeProjectNav() {
        const sidePanel = document.getElementById("projectsNav");
        if (sidePanel) {
            document.getElementById("projectsNav").style.width = "0";
            document.getElementById("projectsNav-content").style.width = "0";
        }
    }

    function changeScreen(project) {
        setProjectsGralState(!projectsGral);
        setProjectDetail(project);
    }

	return (
        <div className="projects row justify-center">
        <Menu />
        <div className="column justify-start">
            <Header />
            <div className="projects-content justify-center">
                <div className="container column">
                    {projectsGral ? <div className="column">
                        <div className="align-center">
                            <h2 className="weight-bold">Proyectos</h2>
                        </div>
                        <div className="white-space-16"></div>
                        <Controls 
                            sidePanel={permission === 1 ? true : false} 
                            btnExport={false} 
                            openPanel={openProjectNav.bind(this)}
                            setFilter={setFilter} />
                        <div className="white-space-24"></div>
                        {projectsFilter.length !== 0 ? 
                            <ProjectsTable 
                                projects={projectsFilter}
                                setProjectsGralState={setProjectsGralState} 
                                projectsGral={projectsGral}
                                openProjectNav={openProjectNav.bind(this)}
                                changeScreen={changeScreen.bind(this)}
                                removeProject={removeProject.bind(this)}
                                permission={permission} /> :
                            <div className="column align-center justify-center">
                                <div className="white-space-16"></div>
                                <h4>No se encontraron resultados.</h4>
                            </div>}
                    </div> :
                    <div className='column'>
                        <Terms project={project} />
                    </div>}
                </div>
            </div>
        </div>
        <ProjectsNav 
            clients={clients} 
            project={projectUpd} 
            closeProjectNav={closeProjectNav.bind(this)} 
            reload={getAllProjects.bind(this)}
            users={users} />
    </div>
	);
};


export default Consumer(Projects);
       
