import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Consumer} from "../context";
import {URL_API} from "../core/urlsApi";
import Request from "../api";
import swal from "sweetalert";

const req = new Request();

const Login = (props) => {
	const [pass_status, setPassStatus] = useState(false);

	async function login(e) {
		e.preventDefault();

		//Muestra overlay
		props.context.showLoading();

		const form = e.target;
		const user = form.username.value;
		const pass = form.password.value;

		const data = {
			User: user,
			Password: pass,
		};
		const res = await req.post(URL_API + "/users/login", data);
		if (res) {
			// cierra el overlay

			if (res.user) {
				props.context.setUser({data: res.user, auth: true});
				setTimeout(() => {
					props.context.closeLoading();

					if (res.user.tipo === 2) {
						props.history.push("/projects/0");
					} else {
						props.history.push("/calendar");
					}
					
				}, 1000);
			}

			if (res.error) {
				swal(res.message, {
					icon: "error",
				});
				props.context.closeLoading();
			}
		}
	}

	return (
		<div className="home justify-center align-center">
			<div className="login">
				<form className="login-box" onSubmit={login.bind(this)}>
					<div>
						<h2 className="text-center">INICIAR SESIÓN</h2>
					</div>
					<div className="white-space-24"></div>
					<div className="user column">
						<label>Usuario</label>
						<div className="white-space-8"></div>
						<input type="email" className="input input-text" name="username" />
					</div>
					<div className="white-space-16"></div>
					<div className="pass column">
						<label>Contraseña</label>
						<div className="white-space-8"></div>
						<div className="row pass-eye">
							<div className="full">
								{pass_status ? (
									<input type="text" className="input input-text full" name="password" />
								) : (
									<input type="password" className="input input-text full" name="password" />
								)}
							</div>
							{pass_status ? (
								<i className="far fa-eye-slash cursor-pointer" onClick={() => setPassStatus(false)}></i>
							) : (
								<i className="far fa-eye cursor-pointer" onClick={() => setPassStatus(true)}></i>
							)}
						</div>
					</div>
					<div className="white-space-16"></div>
					<div className="butn row align-center">
						<div className="align-center justify-end">
							<Link to="/recover">¿Olvidaste tu contraseña?</Link>
						</div>
						<div className="auto space-8">
							<button type="submit" className="btn btn-success">
								Iniciar
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Consumer(Login);
