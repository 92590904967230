import React from "react";
import UsersRow from "./users-row";

const UsersTable = (props) => {
	return (
		<div className="users-table">
			<div className="table-responsive">
				<table>
					<thead>
						<tr className="text-left">
							<th>Nombre</th>
							<th>Usuario</th>
							<th>Rol</th>
							<th>Alta</th>
							<th className="text-center">Editar</th>
							<th className="text-center">Eliminar</th>
						</tr>
					</thead>
					<tbody>
						{props.users.map((user, i) => (
							<UsersRow key={i} user={user} {...props} />
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default UsersTable;
