import React, { useState, useEffect } from 'react';
import ClientsTable from '../components/clients/clients-table';
import Controls from '../components/controls';
import Header from '../components/header';
import Menu from '../components/menu';
import ClientsNav from '../components/clients/clients-nav';
import swal from "sweetalert";
import { URL_API } from "../core/urlsApi";
import Request from "../api";
import {Consumer} from "../context";

const req = new Request();

const Clients = (props) => {
    const [clientes, setClientes] = useState([]);
    const [clientesFilter, setClientesFilter] = useState([]);
    const [clientUpd, setClientUpd] = useState([]);
    const [filter, setFilter] = useState('');
    const [userId, setUserId] = useState(0);
    const [permission, setPermission] = useState(0);

    useEffect(() => {
        const getUserId = async () => {
            const { data } = await props.context?.loadUser();
            if (data) {
                const { id_usuario, tipo } = data;
                if (id_usuario) {
                    setUserId(id_usuario);
                    setPermission(tipo);
                }
            }
        };
        getUserId();

        getClients();
    }, []);

    useEffect(() => {
        // console.log(filter);
        let clients = clientes.filter(cli => {
            if (cli.nombre.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                return true;
            } else if (cli.ciudad.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                return true;
            } else {
                return false;
            }
        })

        setClientesFilter(clients);
    }, [filter]);

    async function getClients() {
		props.context.showLoading();

        const res = await req.get(URL_API + "/clients/getAll");
        if (res) {
            if (res.clients) {
                setClientes(res.clients);
                setClientesFilter(res.clients);
            }
        }
		props.context.closeLoading();

    }

    function openConditionNav(client) {
        setClientUpd(client);

        const sidePanel = document.getElementById("clientsNav");
        if (sidePanel) {
            sidePanel.style.width = "100%";
            document.getElementById("clientsNav-content").style.width = "600px";
        }
    }

    function closeConditionNav() {
        const sidePanel = document.getElementById("clientsNav");
        if (sidePanel) {
            document.getElementById("clientsNav").style.width = "0";
            document.getElementById("clientsNav-content").style.width = "0";
        }
    }

    async function removeClient(id) {
        swal({
            title: "¿Desea eliminar cliente?",
            text: "Una vez eliminado, los datos no podrán ser recuperados.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const res = await req.post(URL_API + "/clients/remove", { id_client: id });
                if (res.deleted) {
                    swal("¡Listo! Cliente eliminado.", {
                        icon: "success",
                    });
                    getClients();
                    setClientUpd([]);
                }

                if (res.error) {
                    swal(res.message, {
                        icon: "error",
                    });
                }
            }
        });
    }

    return(
        <div className="clients row justify-center">
            <Menu />
            <div className="column justify-start">
                <Header />
                <div className="clients-content justify-center">
                    <div className="container">
                        {permission !== 2 && <div className="column">
                            <div className="align-center">
                                <h2 className="weight-bold">Clientes</h2>
                            </div>
                            <div className="white-space-16"></div>
                            <Controls 
                                sidePanel='true'
                                openPanel={openConditionNav.bind(this)}
                                setFilter={setFilter}
                                 />
                            <div className="white-space-24"></div>
                            <ClientsTable 
                                clientes={clientesFilter} 
                                openConditionNav={openConditionNav.bind(this)}
                                removeClient={removeClient.bind(this)} />
                        </div>}
                    </div>
                </div>
            </div>
            <ClientsNav client={clientUpd} closeConditionNav={closeConditionNav.bind(this)}  getClients={getClients} />
        </div>
    )
}

export default Consumer(Clients);