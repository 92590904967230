import React, {useState, useEffect} from "react";
import {Consumer} from "../context";
import {Link} from "react-router-dom";

const Header = ({context}) => {
	const [username, setUsername] = useState("");
	useEffect(() => {
		const getUsername = async () => {
			const {data} = await context?.loadUser();
			if (data) {
				const {nombre} = data;
				if (nombre) {
					setUsername(nombre);
				}
			}
		};
		getUsername();
	}, []);

	return (
		<div className="header justify-center">
			<div className="container">
				<div className="header-content row align-center justify-end">
					<div className="username justify-end auto">
						<i className="fas fa-user"></i>&nbsp;<h5 className="weight-regular">{username}</h5>
					</div>
					<div className="logout justify-end auto">
						<Link to="/login" className="weight-regular" onClick={() => context.logout()}>
							Cerrar sesión
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Consumer(Header);
