import React, {Component, createContext} from "react";
import {setProjects, getProjects} from "./actions/projects";

const Context = createContext();

class GlobalContext extends Component {
	constructor(props) {
		super(props);
		this.state = {
			setUser: this.setUser.bind(this),
			user: this.loadUser(),
			logout: this.logout.bind(this),
			imagen_zoom: "",
			close_zoom: true,
			setZoom: this.setZoom.bind(this),
			loadUser: this.loadUser.bind(this),
			setProjects: setProjects.bind(this),
			projects: [],
			getProjects: getProjects.bind(this),
			showLoading: this.showLoading.bind(this),
			closeLoading: this.closeLoading.bind(this),
		};
	}

	async componentDidMount() {
		let user = await window.localStorage.getItem("bdusr");
		if (user) {
			//// console.log(user);
			user = JSON.parse(user);
			this.setState({user: user});
		}
	}

	loadUser() {
		let user = window.localStorage.getItem("psusr");
		if (user) {
			user = JSON.parse(user);
			return user;
		} else {
			return {auth: false};
		}
	}

	setUser(user) {
		window.localStorage.setItem("psusr", JSON.stringify(user));
		this.setState({user});
	}

	logout() {
		window.localStorage.setItem("psusr", '{"auth": false}');
		this.setState({user: {auth: false}});
	}

	setZoom(imagen, close) {
		if (imagen) {
			this.setState({imagen_zoom: imagen, close_zoom: close});
		}
		return imagen;
	}

	showLoading() {
		const modal = document.getElementById("loadingOverlay");
		if (modal) {
			modal.classList.remove("fadeIn");
			modal.style.display = "flex";
		}
	}

	closeLoading() {
		const modal = document.getElementById("loadingOverlay");
		if (modal) {
			modal.classList.remove("fadeIn");
			modal.classList.add("animated");
			modal.classList.add("fadeOut");
			setTimeout(() => {
				modal.style.display = "none";
				modal.classList.remove("fadeOut");
				//this.close();
			}, 500);
		}
	}

	render() {
		return <Context.Provider value={this.state}>{this.props.children}</Context.Provider>;
	}
}

const Consumer = (Component) => {
	return (props) => {
		return <Context.Consumer>{(context) => <Component {...props} context={context} />}</Context.Consumer>;
	};
};

export {Consumer, GlobalContext};
