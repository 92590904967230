import React, {useState} from "react";

const Accordion = (props) => {
	const {title, content, removeTerm, term, openTermNav, permission} = props;
	const [isActive, setIsActive] = useState(false);
	const [options, setOptions] = useState(false);

	function accordionClick() {
		setIsActive(!isActive);
		setOptions(false);
	}

	return (
		<div className="accordion-item">
			<div className="row">
				<div className="accordion-title row justify-between align-center" onClick={accordionClick.bind(this)}>
					<div className="row">
						<div className="a-title auto">
							<h5 className="weight-medium">{title}</h5>
						</div>
						<div className={`a-description auto space-16 ${isActive ? "hidden" : ""}`}>
							<h5 className="truncate-ellipsis weight-regular">{content}</h5>
						</div>
					</div>
					<div className="a-icon auto justify-end">
						<i className="fas fa-chevron-right toggle" aria-expanded={isActive} />
					</div>
				</div>
				{permission !== 2 && isActive && (
					<div className="actions auto align-center" onClick={() => setOptions(!options)}>
						<i className="fas fa-ellipsis-v cursor-pointer"></i>
					</div>
				)}
			</div>
			<div className="accordion-content" aria-expanded={!isActive}>
				{props.children}
				{options && (
					<div className="actions-box auto column">
						<div className="pestaña-top cursor-pointer" onClick={openTermNav.bind(this, term)}>
							<h5 className="text-center weight-regular">Editar</h5>
						</div>
						<div className="pestaña-bottom cursor-pointer" onClick={removeTerm.bind(this, term.id_termino)}>
							<h5 className="text-center weight-regular">Eliminar</h5>
						</div>
					</div>
				)}
			</div>
		</div>
	);

	/*return (
        <div className="container">
            <div className="column">
                {/*<div className="accordion-option">
                    <h3 className="title">Lorem Ipsum</h3>
                    <a onClick={accordionClick()} className="toggle-accordion active" accordion-id="#accordion"></a>
                </div>
                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="headingOne" onClick={accordionClick.bind(this)}>
                            <h4 className="panel-title">
                                <a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded={selectAria} aria-controls="collapseOne">
                                    Collapsible Group Item #1
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                            <div className="panel-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                                on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                                raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="headingTwo">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="true" aria-controls="collapseTwo">
                                    Collapsible Group Item #2
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingTwo">
                            <div className="panel-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                                on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                                raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="headingThree">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                    Collapsible Group Item #3
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingThree">
                            <div className="panel-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                                on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                                raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='white-space-8'></div>
                <div>
                    <label for="state">
                        <div className="button">Button</div>
                    </label>
                    <input type="checkbox" id="state" hidden />
                    <div className="content">
                        Content<br />
                        Content<br />
                        Content<br />
                        ...
                    </div>
                </div>
            </div>
        </div>
    )*/
};

export default Accordion;
