import React, {useState, useEffect} from "react";
import {URL_API} from "../../core/urlsApi";
import Request from "../../api";
import swal from "sweetalert";

const req = new Request();

const UserNav = ({user, closeConditionNav, getUsers}) => {
	const [name, setName] = useState("");
	const [userName, setUserName] = useState("");
	const [pass, setPass] = useState("");
	const [admin, setAdmin] = useState(false);
	const [externo, setExterno] = useState(false);
	const [tipo, setTipo] = useState(false);

	useEffect(() => {
		setName(user.nombre || "");
		setUserName(user.usuario || "");
		setPass(user.pass || "");
		setTipo(user.tipo || 0);
		console.log(user.tipo);

		switch (user.tipo) {
			case 0:
				setAdmin(false);
				setExterno(false);
				break;

			case 1:
				setAdmin(true);
				setExterno(false);
				break;

			case 2:
				setExterno(true);
				setAdmin(false);
				break;

			case null:
				setAdmin(false);
				setExterno(false);
				break;
		
			default:
				break;
		}
	}, [user]);

	//Tipo - 0:Usuario, 1: Admin, 2: Externo
	function newUser(e) {
		e.preventDefault();

		const form = e.target;

		const data = {
			id_usuario: 0,
			nombre: form.nombre.value,
			usuario: form.usuario.value,
			pass: form.password.value,
			tipo
		};

		saveClient(data);
	}

	function updateUser() {
		const data = {
			id_usuario: user.id_usuario,
			nombre: name,
			usuario: userName,
			pass,
			tipo
		};

		saveClient(data);
	}

	async function saveClient(data) {
		const res = await req.post(URL_API + "/users/add", data);
		if (res) {
			if (res.inserted || res.updated) {
				swal(res.message, {
					icon: "success",
				});
				getUsers();
				closeConditionNav();
			}else if(res.error){
				swal(res.message, {
					icon: "warning",
				});
			}
		}
	}

	async function setTipoUsuario(state, tipo) {
		switch (tipo) {
			case 1:
				setAdmin(state);
				if (state) {
					setTipo(tipo);
					setExterno(false);
				} else {
					setTipo(0);
				}
				break;

			case 2:
				setExterno(state);
				if (state) {
					setTipo(tipo);
					setAdmin(false);
				} else {
					setTipo(0);
				}
				break;
		
			default:
				break;
		}


	}

	return (
		<div id="clientsNav" className="sidepanel">
			<div id="clientsNav-content" className="sidepanel-content">
				<a className="closebtn" onClick={closeConditionNav}>
					&times;
				</a>
				{user?.id_usuario ? (
					<div className="column align-center">
						<div className="header-title">
							<h4 className="weight-regular">Editar usuario</h4>
						</div>
						<div className="content-inputs column">
							<div className="column">
								<label>Nombre</label>
								<div className="white-space-8"></div>
								<input className="input" type="text" value={name} onChange={(e) => setName(e.target.value)} />
							</div>
							<div className="white-space-16"></div>
							<div className="column">
								<label>Usuario</label>
								<div className="white-space-8"></div>
								<input className="input" type="email" value={userName} onChange={(e) => setUserName(e.target.value)} />
							</div>
							<div className="white-space-16"></div>
							<div className="row">
								<div className="column">
									<label>Contraseña</label>
									<div className="white-space-8"></div>
									<input className="input" type="password" value={pass} onChange={(e) => setPass(e.target.value)} />
								</div>
							</div>
							<div className="white-space-16"></div>
							<div className="row align-center">
								<div className="auto 
								align-center">
									<input
										className="check"
										type="checkbox"
										name="useradmin"
										id="useradmin"
										style={{ display: "none" }}
										checked={admin}
										onChange={(e) => setTipoUsuario(e.target.checked, 1)}
									/>
									<label htmlFor="useradmin" className="check">
										<svg width="18px" height="18px" viewBox="0 0 18 18">
											<path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
											<polyline points="1 9 7 14 15 4"></polyline>
										</svg>
									</label>
								</div>
								<div className="auto space-8">
									<label>Administrador</label>
								</div>
							</div>
							<div className="white-space-16"></div>
							<div className="row align-center">
								<div className="auto align-center">
									<input
										className="check"
										type="checkbox"
										name="userexterno"
										id="userexterno"
										style={{ display: "none" }}
										checked={externo}
										onChange={(e) => setTipoUsuario(e.target.checked, 2)}
									/>
									<label htmlFor="userexterno" className="check">
										<svg width="18px" height="18px" viewBox="0 0 18 18">
											<path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
											<polyline points="1 9 7 14 15 4"></polyline>
										</svg>
									</label>
								</div>
								<div className="auto space-8">
									<label>Externo</label>
								</div>
							</div>
							<div className="white-space-24"></div>
							<div className="column">
								<button type="button" className="btn btn-success" onClick={updateUser}>
									<i className="fas fa-save"></i>&nbsp; Guardar
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className="column align-center">
						<div className="header-title">
							<h4 className="weight-regular">Nuevo usuario</h4>
						</div>
						<form className="content-inputs column" autoComplete="off" onSubmit={newUser}>
							<div className="column">
								<label>Nombre</label>
								<div className="white-space-8"></div>
								<input
									className="input"
									name="nombre"
									type="text"
									autoComplete="off"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div className="white-space-16"></div>
							<div className="column">
								<label>Usuario</label>
								<div className="white-space-8"></div>
								<input
									className="input"
									name="usuario"
									type="email"
									autoComplete="off"
									value={userName}
									onChange={(e) => setUserName(e.target.value)}
								/>
							</div>
							<div className="white-space-16"></div>
							<div className="row">
								<div className="column">
									<label>Contraseña</label>
									<div className="white-space-8"></div>
									<input
										className="input"
										name="password"
										type="password"
										autoComplete="off"
										value={pass}
										onChange={(e) => setPass(e.target.value)}
									/>
								</div>
							</div>
							<div className="white-space-16"></div>
							<div className="row align-center">
								<div className="auto align-center">
									<input
										className="check"
										type="checkbox"
										name="useradmin"
										id="useradmin"
										style={{ display: "none" }}
										checked={admin}
										onChange={(e) => setTipoUsuario(e.target.checked, 1)}
									/>
									<label htmlFor="useradmin" className="check">
										<svg width="18px" height="18px" viewBox="0 0 18 18">
											<path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
											<polyline points="1 9 7 14 15 4"></polyline>
										</svg>
									</label>
								</div>
								<div className="auto space-8">
									<label>Administrador</label>
								</div>
							</div>
							<div className="white-space-16"></div>
							<div className="row align-center">
								<div className="auto align-center">
									<input
										className="check"
										type="checkbox"
										name="userexterno"
										id="userexterno"
										style={{ display: "none" }}
										checked={externo}
										onChange={(e) => setTipoUsuario(e.target.checked, 2)}
									/>
									<label htmlFor="userexterno" className="check">
										<svg width="18px" height="18px" viewBox="0 0 18 18">
											<path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
											<polyline points="1 9 7 14 15 4"></polyline>
										</svg>
									</label>
								</div>
								<div className="auto space-8">
									<label>Externo</label>
								</div>
							</div>
							<div className="white-space-24"></div>
							<div className="column">
								<button type="submit" className="btn btn-success">
									<i className="fas fa-save"></i>&nbsp; Guardar
								</button>
							</div>
						</form>
					</div>
				)}
			</div>
		</div>
	);
};

export default UserNav;
