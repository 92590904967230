import React, {useState, useEffect} from "react";
import {URL_API} from "../../core/urlsApi";
import Request from "../../api";
import swal from "sweetalert";

const req = new Request();

const TermNav = ({closeTermNav, term, project, load}) => {
	const [loadingSave, setLoadingSave] = useState(false);
	const [name, setTermName] = useState(term.nombre);
	const [description, setTermDescription] = useState(term.description);
	const [projectInfo, setProjectInfo] = useState(project);
	const [terminosArray, setTerminos] = useState([]);

	useEffect(() => {
		if (term) {
			if (term.length !== 0) {
				setTermName(term.nombre);
				setTermDescription(term.description);
			} else {
				colocaValor("term_title", "");
				colocaValor("term_desc", "");
			}
		}
	}, [term]);

	useEffect(() => {
		if (project) {
			setProjectInfo(project);

			if (project.terminos !== null && project.terminos !== "" && project.length !== 0) {
				const terms = project.terminos;
				setTerminos(terms);
			}
		}
	}, [project]);

	async function colocaValor(id, valor) {
		const inputs = document.getElementsByName(id);
		if (inputs) {
			inputs.forEach((input) => {
				input.value = valor;
			});
		}
	}

	function newTerm(e) {
		e.preventDefault();
		setLoadingSave(true);

		const form = e.target;
		const termino = {
			id_termino: terminosArray.length + 1,
			nombre: form.term_title.value,
			description: form.term_desc.value,
			condiciones: "",
			status: 1,
		};

		terminosArray.push(termino);

		const data = {
			insert: true,
			id_proyecto: project.id_proyecto,
			terminos: terminosArray,
		};

		saveNewTerm(data);
	}

	function updTerm() {
		setLoadingSave(true);

		terminosArray.map((tmo) => {
			if (tmo.id_termino === term.id_termino) {
				tmo.nombre = name;
				tmo.description = description;
			}
		});

		const data = {
			insert: false,
			id_proyecto: project.id_proyecto,
			terminos: terminosArray,
		};

		saveNewTerm(data);
	}

	async function saveNewTerm(data) {
		const res = await req.post(URL_API + "/projects/addTerm", data);
		if (res) {
			if (res.inserted) {
				swal("Nuevo término agregado.", {
					icon: "success",
				});

				closeTermNav();
				load();
			}

			if (res.updated) {
				swal("Datos de término actualizados.", {
					icon: "success",
				});

				closeTermNav();
				load();
			}
		}
		setLoadingSave(false);
	}

	return (
		<div id="termNav" className="sidepanel">
			<div id="termNav-content" className="sidepanel-content">
				<a className="closebtn" onClick={closeTermNav.bind(this)}>
					&times;
				</a>
				{term.length !== 0 ? (
					<div className="column align-center">
						<div className="header-title">
							<h4 className="weight-regular">Nuevo término</h4>
						</div>
						<div className="content-inputs column">
							<div className="column">
								<label>Nombre</label>
								<div className="white-space-8"></div>
								<input className="input" type="text" value={name} onChange={(e) => setTermName(e.target.value)} />
							</div>
							<div className="white-space-16"></div>
							<div className="column">
								<label>Descripción</label>
								<div className="white-space-8"></div>
								<textarea
									className="input full"
									id="term_desc"
									cols="30"
									rows="9"
									value={description}
									onChange={(e) => setTermDescription(e.target.value)}
								></textarea>
							</div>
							<div className="white-space-24"></div>
							<div className="column">
								<button type="submit" className="btn btn-success" onClick={updTerm.bind(this)}>
									{loadingSave ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-save"></i>}&nbsp;
									Guardar
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className="column align-center">
						<div className="header-title">
							<h4 className="weight-regular">Nuevo término</h4>
						</div>
						<form className="content-inputs column" onSubmit={newTerm.bind(this)}>
							<div className="column">
								<label>Nombre</label>
								<div className="white-space-8"></div>
								<input className="input" type="text" name="term_title" />
							</div>
							<div className="white-space-16"></div>
							<div className="column">
								<label>Descripción</label>
								<div className="white-space-8"></div>
								<textarea className="input full" name="term_desc" id="term_desc" cols="30" rows="9"></textarea>
							</div>
							<div className="white-space-24"></div>
							<div className="column">
								<button type="submit" className="btn btn-success">
									{loadingSave ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-save"></i>}&nbsp;
									Guardar
								</button>
							</div>
						</form>
					</div>
				)}
			</div>
		</div>
	);
};

export default TermNav;
