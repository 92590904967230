import React from 'react';
import ProjectsRow from './projects-row';

const ProjectsTable = ({ setProjectsGralState, projectsGral, openProjectNav, projects, changeScreen, removeProject, permission }) => {
    return (
        <div className="projects-table">
            <div className="table-responsive">
                <table>
                    <thead>
                        <tr className="text-left">
                            <th>Proyecto</th>
                            <th>Cliente</th>
                            {permission === 1 && 
                                <React.Fragment>
                                    <th className="text-center">Editar</th>
                                    <th className="text-center">Eliminar</th>
                                </React.Fragment>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {projects.map((project, i) => 
                            <ProjectsRow 
                                key={i}
                                project={project}
                                setProjectsGralState={setProjectsGralState} 
                                projectsGral={projectsGral}
                                openProjectNav={openProjectNav.bind(this)}
                                changeScreen={changeScreen.bind(this)}
                                removeProject={removeProject.bind(this)}
                                permission={permission} />)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ProjectsTable;