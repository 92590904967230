import React from "react";
import { Link } from "react-router-dom";

const ClientsRow = ({client, openConditionNav, removeClient}) => {
	return (
		<tr className="table-row text-left">
			<td>
				<Link className="weight-regular cursor-pointer" to={`/projects/${client.id_client}`}>
					{client.nombre}
				</Link>  
			</td>
			<td> {client.ciudad} </td>
			<td> {client.correo} </td>
			<td> {client.telefono} </td>
			<td className="text-center">
				<i className="fas fa-edit cursor-pointer" onClick={openConditionNav.bind(this, client)} />{" "}
			</td>
			<td className="text-center">
				<i className="fas fa-trash cursor-pointer" onClick={removeClient.bind(this, client.id_client)} />{" "}
			</td>
		</tr>
	);
};

export default ClientsRow;
