import React, { useState, useEffect } from 'react';
import { URL_API } from "../../core/urlsApi";
import Request from "../../api";
import swal from "sweetalert";

const req = new Request();

const ProjectsNav = ({ project, closeProjectNav, clients, reload, users }) => {
    const [loadingSave, setLoadingSave] = useState(false);
    const [name, setProjectName] = useState(project.nombre);
    const [description, setProjectDescription] = useState(project.descripcion);
    const [client, setProjectClient] = useState(project.id_cliente);
    const [usersProject, setUsersProject] = useState([]);
    const [usuarioLoggeado, setUsuarioLoggeado] = useState([]);

    useEffect(() => {
        let userLogin = window.localStorage.getItem("psusr");
        if (userLogin) {
            userLogin = JSON.parse(userLogin);
            setUsuarioLoggeado(userLogin.data);
        }
    }, []);

    useEffect(() => {
        let uP = [];

        if (project.length !== 0) {
            setProjectName(project.nombre);
            setProjectDescription(project.descripcion);
            setProjectClient(project.cliente);
            loadUsers();
        } else {
            colocaValor('term_title', '');
            colocaValor('term_desc', '');
            //colocaValor('cliente', '');

            uP = users.map(usr => {
                if (usr.id_usuario === usuarioLoggeado.id_usuario || usr.tipo === 1) {
                    usr['checked'] = true;
                    usr['notifications'] = true;
                    usr['download'] = true;
                } else {
                    usr['checked'] = false;
                    usr['notifications'] = false;
                    usr['download'] = false;
                }

                return usr
            });

            setUsersProject(uP);
        }
    }, [project]);

    async function colocaValor(id, valor) {
        const inputs = document.getElementsByName(id);
        if (inputs) {
            inputs.forEach(input => {
                input.value = valor;
            });
        }
    }

    function loadUsers() {
        let uP = [];
        if (project.usuarios) {
            console.log(project.usuarios);
            if (project.usuarios.length !== 0) {
                uP = users.map(usr => {
                    let found = project.usuarios.find(up => up.id_usuario == usr.id_usuario);
                    //console.log(found);
                    if (found) {
                        console.log(found);
                        usr['checked'] = true;
                        usr['notifications'] = found.notificaciones === null || found.notificaciones === 0 ? false : true;
                        usr['download'] = found.descargas === null || found.descargas === 0 ? false : true;
                    } else {
                        usr['checked'] = false;
                        usr['notifications'] = false;
                        usr['download'] = false;
                    }

                    return usr;
                });
            } else {
                uP = users.map(usr => {
                    console.log(usr);
                    if (usr.id_usuario === usuarioLoggeado.id_usuario || usr.tipo === 1) {
                        usr['checked'] = true;
                        usr['notifications'] = true;
                        usr['download'] = true;
                    } else {
                        usr['checked'] = false;
                        usr['notifications'] = false;
                        usr['download'] = false;
                    }

                    return usr
                });
            }
        } else {
            uP = users.map(usr => {
                console.log(usr);
                if (usr.id_usuario === usuarioLoggeado.id_usuario || usr.tipo === 1) {
                    usr['checked'] = true;
                    usr['notifications'] = true;
                    usr['download'] = true;
                } else {
                    usr['checked'] = false;
                    usr['notifications'] = false;
                    usr['download'] = false;
                }

                return usr
            });
        }

        setUsersProject(uP);
    }

    function newProject(e) {
        e.preventDefault();
        setLoadingSave(true);

        const aUsers = [];
        usersProject.forEach(up => {
            if (up.checked) {
                aUsers.push({ 
                    id_usuario: up.id_usuario, 
                    notificaciones: up.notifications ? 1 : 0, 
                    descargas: up.download ? 1 : 0 
                });
            }
        });
        
        if (aUsers.length !== 0) {
            const form = e.target;
            const data = {
                id_proyecto: 0,
                nombre: form.term_title.value,
                description: form.term_desc.value,
                cliente: parseInt(form.cliente.value),
                usuarios: aUsers
            }
            //console.log(data);

            saveNewProject(data);
        } else {
            swal("Es necesario agregar un usuario por proyecto.", {
                icon: "warning",
            });

            setLoadingSave(false);
            return;
        }
    }

    function updProject() {
        setLoadingSave(true);
        
        const aUsers = [];
        usersProject.forEach(up => {
            if (up.checked) {
                aUsers.push({ 
                    id_usuario: up.id_usuario,
                    notificaciones: up.notifications ? 1 : 0,
                    descargas: up.download ? 1 : 0 
                });
            }
        });

        if (aUsers.length !== 0) {
            const data = {
                id_proyecto: project.id_proyecto,
                nombre: name,
                description,
                cliente: parseInt(client),
                usuarios: aUsers
            }

            saveNewProject(data);
        } else {
            swal("Es necesario agregar un usuario por proyecto.", {
                icon: "warning",
            });

            setLoadingSave(false);
            return;
        }
    }

    async function saveNewProject(data) {
        const res = await req.post(URL_API + "/projects/add", data); 
        
        if (res) {
            if (res.inserted) {
                swal(res.message, {
                    icon: "success",
                });

                closeProjectNav();
                reload(0, usuarioLoggeado.id_usuario);
            }

            if (res.updated) {
                swal(res.message, {
                    icon: "success",
                });

                closeProjectNav();
                reload(0, usuarioLoggeado.id_usuario);
            }
        }
        setLoadingSave(false);
    }

    function updUser(data) {
        let newUP = [];
        newUP = usersProject.map(up => {
            if (up.id_usuario == data.id_usuario) {
                up.checked = !up.checked;
                
                if (up.checked) {
                    up.notifications = true;
                    up.download = true;
                } else {
                    up.notifications = false;
                    up.download = false;
                }
            }

            return up;
        });

        setUsersProject(newUP);
    }

    //PN: Permiso de notificaciones.
    function updPN(data) {
        let newUP = [];
        newUP = usersProject.map(up => {
            if (up.id_usuario == data.id_usuario) {
                up.notifications = !up.notifications;
            }

            return up;
        });

        setUsersProject(newUP);
    }

    //PD: Permiso de descargas.
    function updPD(data) {
        let newUP = [];
        newUP = usersProject.map(up => {
            if (up.id_usuario == data.id_usuario) {
                up.download = !up.download;
            }

            return up;
        });

        setUsersProject(newUP);
    }

    function closeNav() {
        closeProjectNav();
        loadUsers();
    }

    return (
        <div id="projectsNav" className="sidepanel">
            <div id="projectsNav-content" class="sidepanel-content">
                <a class="closebtn" onClick={closeNav.bind(this)}>&times;</a>
                {project.length !== 0 ?
                    <div className="column align-center">
                        <div className="header-title">
                            <h4 className="weight-regular">Editar proyecto</h4>
                        </div>
                        <div className="content-inputs column">
                            <div className="column">
                                <label>Nombre del proyecto</label>
                                <div className="white-space-8"></div>
                                <input
                                    className="input"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setProjectName(e.target.value)} />
                            </div>
                            <div className="white-space-16"></div>
                            <div className="column">
                                <label>Descripción</label>
                                <div className="white-space-8"></div>
                                <textarea 
                                    className="input full"
                                    id="term_desc" 
                                    cols="30" 
                                    rows="9"
                                    value={description}
                                    onChange={(e) => setProjectDescription(e.target.value)}></textarea>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="column">
                                <label>Cliente</label>
                                <div className="white-space-8"></div>
                                <select className="input" value={client} onChange={(e) => setProjectClient(e.target.value)} id="cliente">
                                    {clients.map((client, i) => 
                                        <option key={i} value={client.id_client}>{client.nombre}</option>)}
                                </select>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="column">
                                <label>Usuarios</label>
                                <div className="white-space-8"></div>
                                <div className="users-table-permisos table-responsive">
                                    <table>
                                        <thead>
                                            <tr className="text-left">
                                                <th>Usuarios</th>
                                                <th className="text-center">Notificaciones</th>
                                                <th className="text-center">Descargas</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usersProject.map((user, i) =>
                                                <tr>
                                                    <td>
                                                        <div className="box-u row align-center">
                                                            {user.tipo !== 1 ?
                                                                <div className="auto">
                                                                    <input
                                                                        className="check"
                                                                        type="checkbox"
                                                                        name="user"
                                                                        id={`user${i}`}
                                                                        checked={user.checked}
                                                                        style={{ display: "none" }}
                                                                        onChange={(e) => updUser(user)}
                                                                    />
                                                                    <label htmlFor={`user${i}`} className="check">
                                                                        <svg width="18px" height="18px" viewBox="0 0 18 18">
                                                                            <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                                                            <polyline points="1 9 7 14 15 4"></polyline>
                                                                        </svg>
                                                                    </label>
                                                                </div> :
                                                                <div className="auto">
                                                                    <input
                                                                        className="check"
                                                                        type="checkbox"
                                                                        name="user"
                                                                        id={`user${i}`}
                                                                        checked={user.checked}
                                                                        style={{ display: "none" }}
                                                                    />
                                                                    <label htmlFor={`user${i}`} className="check">
                                                                        <svg width="18px" height="18px" viewBox="0 0 18 18">
                                                                            <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                                                            <polyline points="1 9 7 14 15 4"></polyline>
                                                                        </svg>
                                                                    </label>
                                                                </div>
                                                            }   
                                                            <div className="auto space-8">
                                                                <h5 className="weight-regular">{user.nombre}</h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {user.checked && 
                                                        <React.Fragment>
                                                            <td className="text-center">
                                                                <div>
                                                                    <input
                                                                        className="check"
                                                                        type="checkbox"
                                                                        name="notification"
                                                                        id={`notification${i}`}
                                                                        checked={user.notifications}
                                                                        onChange={(e) => updPN(user)}
                                                                        style={{ display: "none" }}
                                                                    />
                                                                    <label htmlFor={`notification${i}`} className="check">
                                                                        <svg width="18px" height="18px" viewBox="0 0 18 18">
                                                                            <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                                                            <polyline points="1 9 7 14 15 4"></polyline>
                                                                        </svg>
                                                                    </label>
                                                                </div>
                                                            </td>
                                                            <td className="text-center">
                                                                <div>
                                                                    <input
                                                                        className="check"
                                                                        type="checkbox"
                                                                        name="download"
                                                                        id={`download${i}`}
                                                                        checked={user.download}
                                                                        style={{ display: "none" }}
                                                                        onChange={(e) => updPD(user)}
                                                                    />
                                                                    <label htmlFor={`download${i}`} className="check">
                                                                        <svg width="18px" height="18px" viewBox="0 0 18 18">
                                                                            <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                                                            <polyline points="1 9 7 14 15 4"></polyline>
                                                                        </svg>
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        </React.Fragment>
                                                    }
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                                {/*<div className="box-users">
                                    {usersProject.map((usr, i) =>
                                        <div className="box-u row align-center" key={i}>
                                            <div className="auto">
                                                <input
                                                    className="check"
                                                    type="checkbox"
                                                    name="user"
                                                    id={`user${i}`}
                                                    style={{ display: "none" }}
                                                    checked={usr.checked}
                                                    onChange={() => updUser(usr)}
                                                />
                                                <label htmlFor={`user${i}`} className="check">
                                                    <svg width="18px" height="18px" viewBox="0 0 18 18">
                                                        <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                                        <polyline points="1 9 7 14 15 4"></polyline>
                                                    </svg>
                                                </label>
                                            </div>
                                            <div className="auto space-8">
                                                <h5 className="weight-regular">{usr.nombre}</h5>
                                            </div>
                                        </div>
                                    )}
                                    </div>*/}
                            </div>
                            <div className="white-space-24"></div>
                            <div className="column">
                                <button type="submit" className="btn btn-success" onClick={updProject.bind(this)}>
                                    {loadingSave ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-save"></i>}&nbsp; Guardar
                                </button>
                            </div>
                        </div>
                    </div> :
                    <div className="column align-center">
                        <div className="header-title">
                            <h4 className="weight-regular">Nuevo proyecto</h4>
                        </div>
                        <form className="content-inputs column" onSubmit={newProject.bind(this)}>
                            <div className="column">
                                <label>Nombre del proyecto</label>
                                <div className="white-space-8"></div>
                                <input
                                    className="input"
                                    type="text"
                                    name="term_title"
                                    required />
                            </div>
                            <div className="white-space-16"></div>
                            <div className="column">
                                <label>Descripción</label>
                                <div className="white-space-8"></div>
                                <textarea className="input full" name="term_desc" id="term_desc" cols="30" rows="9" required></textarea>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="column">
                                <label>Cliente</label>
                                <div className="white-space-8"></div>
                                <select className="input" name="cliente" id="cliente">
                                    {clients.map((client, i) =>
                                        <option key={i} value={client.id_client}>{client.nombre}</option>)}
                                </select>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="users-table-permisos table-responsive">
                                <table>
                                    <thead>
                                        <tr className="text-left">
                                            <th>Usuarios</th>
                                            <th className="text-center">Notificaciones</th>
                                            <th className="text-center">Descargas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {usersProject.map((user, i) =>
                                            <tr>
                                                <td>
                                                    <div className="box-u row align-center">
                                                        {user.tipo !== 1 ?
                                                            <div className="auto">
                                                                <input
                                                                    className="check"
                                                                    type="checkbox"
                                                                    name="user"
                                                                    id={`user${i}`}
                                                                    checked={user.checked}
                                                                    style={{ display: "none" }}
                                                                    onChange={(e) => updUser(user)}
                                                                />
                                                                <label htmlFor={`user${i}`} className="check">
                                                                    <svg width="18px" height="18px" viewBox="0 0 18 18">
                                                                        <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                                                        <polyline points="1 9 7 14 15 4"></polyline>
                                                                    </svg>
                                                                </label>
                                                            </div> :
                                                            <div className="auto">
                                                                <input
                                                                    className="check"
                                                                    type="checkbox"
                                                                    name="user"
                                                                    id={`user${i}`}
                                                                    checked={user.checked}
                                                                    style={{ display: "none" }}
                                                                />
                                                                <label htmlFor={`user${i}`} className="check">
                                                                    <svg width="18px" height="18px" viewBox="0 0 18 18">
                                                                        <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                                                        <polyline points="1 9 7 14 15 4"></polyline>
                                                                    </svg>
                                                                </label>
                                                            </div>
                                                        }
                                                        <div className="auto space-8">
                                                            <h5 className="weight-regular">{user.nombre}</h5>
                                                        </div>
                                                    </div>
                                                </td>
                                                {user.checked &&
                                                    <React.Fragment>
                                                        <td className="text-center">
                                                            <div>
                                                                <input
                                                                    className="check"
                                                                    type="checkbox"
                                                                    name="notification"
                                                                    id={`notification${i}`}
                                                                    checked={user.notifications}
                                                                    onChange={(e) => updPN(user)}
                                                                    style={{ display: "none" }}
                                                                />
                                                                <label htmlFor={`notification${i}`} className="check">
                                                                    <svg width="18px" height="18px" viewBox="0 0 18 18">
                                                                        <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                                                        <polyline points="1 9 7 14 15 4"></polyline>
                                                                    </svg>
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            <div>
                                                                <input
                                                                    className="check"
                                                                    type="checkbox"
                                                                    name="download"
                                                                    id={`download${i}`}
                                                                    checked={user.download}
                                                                    style={{ display: "none" }}
                                                                    onChange={(e) => updPD(user)}
                                                                />
                                                                <label htmlFor={`download${i}`} className="check">
                                                                    <svg width="18px" height="18px" viewBox="0 0 18 18">
                                                                        <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                                                        <polyline points="1 9 7 14 15 4"></polyline>
                                                                    </svg>
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </React.Fragment>
                                                }
                                            </tr>
                                        )}
                                        
                                    </tbody>
                                </table>
                            </div>
                            {/*<div className="row">
                                <div className="column">
                                    <label>Usuarios</label>
                                    <div className="white-space-8"></div>
                                    <div className="box-users">
                                        {usersProject.map((user, i) =>
                                            <div className="box-u row align-center">
                                                <div className="auto">
                                                    <input
                                                        className="check"
                                                        type="checkbox"
                                                        name="user"
                                                        id={`user${i}`}
                                                        checked={user.checked}
                                                        style={{ display: "none" }}
                                                        onChange={(e) => updUser(user)}
                                                    />
                                                    <label htmlFor={`user${i}`} className="check">
                                                        <svg width="18px" height="18px" viewBox="0 0 18 18">
                                                            <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                                            <polyline points="1 9 7 14 15 4"></polyline>
                                                        </svg>
                                                    </label>
                                                </div>
                                                <div className="auto space-8">
                                                    <h5 className="weight-regular">{user.nombre}</h5>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>

                                </div>
                            </div>*/}
                            <div className="white-space-24"></div>
                            <div className="column">
                                <button type="submit" className="btn btn-success">
                                    {loadingSave ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-save"></i>}&nbsp; Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                }
            </div>
        </div>)
}

export default ProjectsNav;