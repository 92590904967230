import React from "react";
import ClientsRow from "./clients-row";

const ClientsTable = ({clientes, openConditionNav, removeClient}) => {
	return (
		<div className="clients-table">
			<div className="table-responsive">
				<table>
					<thead>
						<tr className="text-left">
							<th>Nombre</th>
							<th>Ciudad</th>
							<th>Correo electrónico</th>
							<th>Teléfono</th>
							<th className="text-center">Editar</th>
							<th className="text-center padding-right-8">Eliminar</th>
						</tr>
					</thead>
					<tbody>
						{clientes.map((client, i) => (
							<ClientsRow
								key={i}
								client={client}
								openConditionNav={openConditionNav.bind(this)}
								removeClient={removeClient.bind(this)}
							/>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default ClientsTable;
