import React from "react";
import moment from "moment-timezone";

const UsersRow = ({user, openConditionNav, removeUser}) => {
	const {id_usuario, usuario, nombre, creado, tipo} = user;
	return (
		<React.Fragment>
			<tr className="table-row text-left">
				<td> {nombre} </td>
				<td> {usuario} </td>
				<td> {tipo === 0 ? 'Interno' : tipo === 1 ? 'Admin' : 'Externo'} </td>
				<td> {moment(creado).format("DD/MM/YYYY")} </td>
				<td className="text-center">
					<i
						className="fas fa-edit cursor-pointer"
						onClick={() => {
							openConditionNav(user);
						}}
					/>
				</td>
				<td className="text-center">
					<i
						className="fas fa-trash cursor-pointer"
						onClick={() => {
							removeUser(id_usuario);
						}}
					/>
				</td>
			</tr>
		</React.Fragment>
	);
};

export default UsersRow;
