import React from 'react';

const Controls = ({ btnExport, openPanel, sidePanel, setFilter }) => {
    return (
        <div className="controls row">
            <div className="input-search">
                <input
                    className="input-filter input"
                    type="text"
                    placeholder="Buscar"
                    onChange={(e) => setFilter(e.target.value)}
                >
                </input>
                <i className="fas fa-search font-small"></i>
            </div>
            <div className="buttons auto row">
                {sidePanel && <button className="btn" onClick={openPanel.bind(this, [])}>Nuevo</button>}
                    {/*: <button className="btn">Nuevo</button>*/}
                {btnExport && <button className="btn space-8">Exportar</button>}
            </div>
        </div>
    )
}

export default Controls;