import {URL_API} from "../../core/urlsApi";
import Request from "../../api";

const req = new Request();

export function setProjects(projects) {
	this.setState({projects});
}

export async function getProjects() {
	const res = await req.get(URL_API + "/projects/getAll");
	if (res) {
		// console.log(res);
		if (res.proyectos) {
			this.setState({projects: res.proyectos});

			return res.proyectos;
		}
	}
}
