import React, { useState, useEffect } from 'react';
import Conditions from '../conditions.jsx/conditions';
import Terms from '../terms/terms';
import { Consumer } from "../../context";
import Accordion from '../accordion';
import Header from '../header';
import Menu from '../menu';
import swal from "sweetalert";
import { URL_API } from "../../core/urlsApi";
import Request from "../../api";

const req = new Request();

const TermsGral = (props) => {
    const [proyectos, setProyectos] = useState([]);
    const [projectSelected, setProjectSelected] = useState([]);
    const [terminos, setTerminos] = useState([]);
    const [terminosFilter, setTerminosFilter] = useState([]);
    const [term, setTerm] = useState([]);
    const [openTermNavState, setOpenTermNavState] = useState(false);
    const [filter, setFilter] = useState('');
    const [userId, setUserId] = useState(0);
    const [permission, setPermission] = useState(0);

	useEffect(() => {
        const getUserId = async () => {
			const {data} = await props.context?.loadUser();
			if (data) {
				const {id_usuario, tipo} = data;
				if (id_usuario) {
					setUserId(id_usuario);
                    setPermission(tipo);
				}
			}
		};
		getUserId();
	}, []);

    useEffect(() => {
        if (userId != 0) {
            load();
        }
    }, [userId]);


    useEffect(() => {
        console.log(filter);
        let terms = terminos.filter(cli => {
            if (cli.nombre.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                return true;
            } else if (cli.description.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
                return true;
            } else {
                return false;
            }
        })

        setTerminosFilter(terms);
    }, [filter]);

    async function load() {
        let id_project = props.match.params.id_term;
        props.context.showLoading();
        if (id_project) {
            const res = await req.post(URL_API + "/projects/getAll",{id_usuario:userId, id_client: 0});
            if (res) {
                console.log(res);
                if (res.proyectos) {
                    setProyectos(res.proyectos);
                    props.context.setProjects(res.proyectos);

                    const prjct = res.proyectos.filter(p => p.id_proyecto == id_project);
                    if (prjct.length !== 0) {
                        console.log(prjct[0]);
                        setProjectSelected(prjct[0]);

                        if (prjct[0].terminos !== null && prjct[0].terminos !== '') {

                            setTerminos(prjct[0].terminos);
                            setTerminosFilter(prjct[0].terminos);
                        }
                    }
                }
            }   
        }
        props.context.closeLoading();
    }

    async function removeTerm(id) {
        console.log(id);
        swal({
            title: "¿Desea eliminar término?",
            text: "Una vez eliminado, los datos no podrán ser recuperados.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                terminos.map((trm) => {
                    if (trm.id_termino == id) {
                        trm.status = 0;
                    }
                });

                const data = {
                    insert: false,
                    id_proyecto: projectSelected.id_proyecto,
                    terminos: terminos
                };

                const res = await req.post(URL_API + "/projects/addTerm", data);
                console.log(res);
                if (res) {
                    if (res.updated) {
                        swal('¡Listo! Término eliminado.', {
                            icon: "success",
                        });

                        closeTermNav();
                        load();
                    }
                }
            }
        });
    }

    async function removeCondition(id) {
        console.log(id);
        swal({
            title: "¿Desea eliminar condición?",
            text: "Una vez eliminado, los datos no podrán ser recuperados.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                terminos.map((trm) => {
                    if (trm.condiciones !== '' || trm.condiciones !== null) {
                        trm.condiciones.map(cond => {
                            if (cond.id_condicion == id) {
                                cond.id_status = 0;
                            }
                        })
                    }
                });

                console.log(terminos);

                const data = {
                    insert: false,
                    id_proyecto: projectSelected.id_proyecto,
                    terminos: terminos
                };

                const res = await req.post(URL_API + "/projects/addTerm", data);
                console.log(res);
                if (res) {
                    if (res.updated) {
                        swal('¡Listo! Condición eliminada.', {
                            icon: "success",
                        });

                        load();
                    }
                }
            }
        });
    }

    function openTermNav(termino) {
        setTerm(termino);
        setOpenTermNavState(true);
        const sidePanel = document.getElementById("termNav");
        const sidePanelContent = document.getElementById("termNav-content");
        if (sidePanel && sidePanelContent) {
            sidePanel.style.width = "100%";
            sidePanelContent.style.width = "600px";
        }
    }

    function closeTermNav() {
        setOpenTermNavState(false);
        const sidePanel = document.getElementById("termNav");
        const sidePanelContent = document.getElementById("termNav-content");
        if (sidePanel && sidePanelContent) {
            sidePanel.style.width = "0";
            sidePanelContent.style.width = "0";
        }
    }

    return (
        <div className="projects row justify-center">
            <Menu />
            <div className="column justify-start">
                <Header />
                <div className="projects-content justify-center">
                    <div className="container column">
                        <div className="terms-gral">
                            <h2>{projectSelected.nombre}</h2>
                            <div className="white-space-16"></div>
                            <h5 className="weight-regular">{projectSelected.descripcion}</h5>
                            <div className="white-space-16"></div>
                            <Terms 
                                accordionData={terminosFilter} 
                                project={projectSelected}
                                removeTerm={removeTerm}
                                openTermNav={openTermNav.bind(this)}
                                closeTermNav={closeTermNav.bind(this)}
                                term={term}
                                load={load.bind(this)}
                                removeCondition={removeCondition}
                                setFilter={setFilter}
                                permission={permission} />
                            {/*{term ?
                                <Terms accordionData={accordionData} conditionsView={conditionsView} project={project} />
                                :
                                <Conditions accordionData={accordionData} condition={condition} term={termSelected} project={project} />
                            }*/}
                            <div className="white-space-32"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Consumer(TermsGral);