import React, { useState, useEffect } from 'react';
import { URL_API } from '../../core/urlsApi';
import Request from '../../api';
import swal from 'sweetalert';
import moment from 'moment-timezone';

const req = new Request();

const ConditionNav = ({ project, closeConditionNav, terms, term, condition, load, id_termino }) => {
	const [loadingSave, setLoadingSave] = useState(false);
	//Campos para editar condición.
	const [name, setConditionName] = useState('');
	const [desc, setConditionDesc] = useState('');
	const [fechaVencimiento, setFechaVencimiento] = useState(new Date());
	const [estatus, setEstatus] = useState('');
	const [plazoRecordatorio, setPlazoRecordatorio] = useState('');
	const [tiempoRecordatorio, setTiempoRecordatorio] = useState(0);

	useEffect(() => {
		setConditionName(condition.nombre);
		setConditionDesc(condition.description);
		setFechaVencimiento(new Date(moment(condition.fecha_vencimiento).toDate()).toISOString().substring(0, 10));
		setEstatus(condition.status);
		setPlazoRecordatorio(condition.plazo_recordatorio);
		setTiempoRecordatorio(parseInt(condition.tiempo_recordatorio));
	}, [condition]);

	useEffect(() => {
		setConditionName('');
		setConditionDesc('');
		setFechaVencimiento(new Date());
		setEstatus('');
		setPlazoRecordatorio('');
		setTiempoRecordatorio('');
	}, [term]);

	async function updCondition() { 
		setLoadingSave(true);

		let terminos = terms.map((trm) => {
			if (id_termino == trm.id_termino) {
				if (trm.condiciones !== '' || trm.condiciones !== null) {
					trm.condiciones.map((con) => {
						if (con.id_condicion == condition.id_condicion) {
							con.nombre = name;
							con.description = desc;
							con.fecha_vencimiento = fechaVencimiento;
							con.status = estatus;
							con.tiempo_recordatorio = tiempoRecordatorio;
							con.plazo_recordatorio = plazoRecordatorio;
						}
					});
				}
			}
			return trm;
		});

		const data = {
			insert: false,
			id_proyecto: project.id_proyecto,
			terminos: terminos,
		};
		console.log(data);
		saveCondition(data);
	}

	async function newCondition(e) {
		e.preventDefault();
		setLoadingSave(true);

		const form = e.target;

		const condicion = {
			id_condicion: Math.floor(Math.random() * 100) * Math.floor(Math.random() * 300),
			nombre: form.condition_title.value,
			description: form.cond_desc.value,
			fecha_vencimiento: fechaVencimiento,
			status: form.estatus.value,
			tiempo_recordatorio: form.tiempo_recordatorio.value,
			plazo_recordatorio: form.plazo_recordatorio.value,
			documentos: [],
			id_status: 1,
		};

		let terminos = terms.map((trm) => {
			if (term.id_termino == trm.id_termino) {
				if (trm.condiciones !== '') {
					trm.condiciones.push(condicion);
				} else {
					trm.condiciones = [condicion];
				}
			}

			return trm;
		});
		const data = {
			insert: true,
			id_proyecto: project.id_proyecto,
			terminos: terminos,
		};
		console.log(data);
		saveCondition(data);
	}

	async function saveCondition(data) {
		const res = await req.post(URL_API + '/projects/addTerm', data);

		if (res) {
			if (res.inserted) {
				swal("Nuevo condicionante agregado.", {
					icon: "success",
				});

				closeConditionNav();
			}

			if (res.updated) {
				swal("Datos de condicionante actualizados.", {
					icon: "success",
				});

				closeConditionNav();
				load();
			}

			if (res.error) {
				swal("Ocurrió un error, no se pudieron actualizar los datos.", {
					icon: "error",
				});

				closeConditionNav();
			}
		}

		setLoadingSave(false);
	}

	return (
		<div id="conditionNav" className="sidepanel">
			<div id="conditionNav-content" className="sidepanel-content">
				<a className="closebtn" onClick={closeConditionNav.bind(this)}>
					&times;
				</a>
				{condition === '' ? (
					<div className="column align-center">
						<div className="header-title">
							<h4 className="weight-regular">Nuevo condicionante</h4>
						</div>
						<form className="content-inputs column" onSubmit={newCondition.bind(this)}>
							<div className="column">
								<label>Nombre</label>
								<div className="white-space-8"></div>
								<input
									className="input"
									type="text"
									name="condition_title"
									required
									value={name}
									onChange={(e) => setConditionName(e.target.value)}
								/>
							</div>
							<div className="white-space-16"></div>
							<div className="column">
								<label>Descripción</label>
								<div className="white-space-8"></div>
								<textarea
									className="input full"
									name="cond_desc"
									cols="30"
									rows="10"
									value={desc}
									onChange={(e) => setConditionDesc(e.target.value)}
								></textarea>
							</div>
							<div className="white-space-16"></div>
							<div className="row justify-between">
								<div className="column">
									<label>Fecha Vencimiento</label>
									<div className="white-space-8"></div>
									<input
										className="input"
										type="date"
										name="fecha_vencimiento"
										value={fechaVencimiento}
										onChange={(e) => setFechaVencimiento(e.target.value)}
										required
									/>
								</div>
								<div className="column space-16">
									<label>Estatus</label>
									<div className="white-space-8"></div>
									<select className="input input-select" name="estatus">
										<option value="Pendiente">Pendiente</option>
										<option value="Vencido">Vencido</option>
									</select>
								</div>
							</div>
							<div className="white-space-16"></div>
							<div className="row align-center">
								<div className="column">
									<label>Recordatorio</label>
									<div className="white-space-8"></div>
									<input
										type="number"
										className="input"
										name="tiempo_recordatorio"
										defaultValue={0}
										min={0}
										value={tiempoRecordatorio}
										onChange={(e) => {
											setTiempoRecordatorio(e.target.value);
										}}
									/>
								</div>
								<div className="column space-16">
									<label>&nbsp;</label>
									<div className="white-space-8"></div>
									<select className="input input-select" name="plazo_recordatorio">
										<option value="days">Días antes</option>
										<option value="weeks">Semanas antes</option>
										<option value="months">Meses antes</option>
										<option value="years">Años antes</option>
									</select>
								</div>
							</div>
							<div className="white-space-24"></div>
							<div className="column">
								<button type="submit" className="btn btn-success">
									{loadingSave ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-save"></i>}&nbsp;
									Guardar
								</button>
							</div>
						</form>
					</div>
				) : (
					<div className="column align-center">
						<div className="header-title">
							<h4 className="weight-regular">Editar condición</h4>
						</div>
						<div className="content-inputs column">
							<div className="column">
								<label>Nombre</label>
								<div className="white-space-8"></div>
								<input
									className="input"
									type="text"
									name="condition_title"
									value={name}
									onChange={(e) => setConditionName(e.target.value)}
								/>
							</div>
							<div className="white-space-16"></div>
							<div className="column">
								<label>Descripción</label>
								<div className="white-space-8"></div>
								<textarea
									className="input full"
									name="cond_desc"
									cols="30"
									rows="10"
									value={desc}
									onChange={(e) => setConditionDesc(e.target.value)}
								></textarea>
							</div>
							<div className="white-space-16"></div>
							<div className="row justify-between">
								<div className="column">
									<label>Fecha Vencimiento</label>
									<div className="white-space-8"></div>
									<input
										className="input"
										type="date"
										name="fecha_vencimiento"
										value={fechaVencimiento}
										onChange={(e) => setFechaVencimiento(e.target.value)}
										required
									/>
								</div>
								<div className="column space-16">
									<label>Estatus</label>
									<div className="white-space-8"></div>
									<select
										className="input input-select"
										name="estatus"
										value={estatus}
										onChange={(e) => setEstatus(e.target.value)}
									>
										<option value="Pendiente">Pendiente</option>
										<option value="Vencido">Vencido</option>
									</select>
								</div>
							</div>
							<div className="white-space-16"></div>
							<div className="row align-center">
								<div className="column">
									<label>Recordatorio</label>
									<div className="white-space-8"></div>
									<input
										type="number"
										className="input"
										name="tiempo_recordatorio"
										defaultValue={0}
										value={tiempoRecordatorio}
										onChange={(e) => {
											setTiempoRecordatorio(e.target.value);
										}}
									/>
								</div>
								<div className="column space-16">
									<label>&nbsp;</label>
									<div className="white-space-8"></div>
									<select
										className="input input-select"
										name="plazo_recordatorio"
										value={plazoRecordatorio}
										onChange={(e) => {
											setPlazoRecordatorio(e.target.value);
										}}
									>
										<option value="days">Días antes</option>
										<option value="weeks">Semanas antes</option>
										<option value="months">Meses antes</option>
										<option value="years">Años antes</option>
									</select>
								</div>
							</div>

							<div className="white-space-24"></div>
							<div className="column">
								<button type="submit" className="btn btn-success" onClick={updCondition}>
									{loadingSave ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-save"></i>}&nbsp;
									Guardar
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ConditionNav;
