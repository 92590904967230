import React, {useState, useEffect} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Header from "../components/header";
import Menu from "../components/menu";
import {Consumer} from "../context";
import {URL_API} from "../core/urlsApi";
import Request from "../api";
import moment from "moment";

const req = new Request();

const CalendarView = ({context}) => {
	const [userId, setUserId] = useState(0);
	const [projects, setProjects] = useState([]);
	const [events, setEvents] = useState([]);
	const [permission, setPermission] = useState(0);

	useEffect(() => {
		const getUserId = async () => {
			const {data} = await context?.loadUser();
			if (data) {
				const {id_usuario, tipo} = data;
				if (id_usuario) {
					setUserId(id_usuario);
					setPermission(tipo);
				}
			}
		};
		getUserId();
	}, []);

	useEffect(() => {
		if (userId != 0) {
			getProjects();
		}
	}, [userId]);

	useEffect(() => {
		if (projects.length > 0) {
			projects.map((prj) => {
				const {terminos} = prj;

				if (terminos && terminos.length > 0) {
					terminos.map((trm) => {
						const {condiciones} = trm;
						if (condiciones && condiciones.length > 0) {
							condiciones.map((cnd) => {
                                console.log(moment(new Date()).format("YYYY-MM-DD"));
								setEvents((ev) => [
									...ev,
									{
										id: cnd.id_condicion,
										title: cnd.nombre,
										start: moment(cnd.fecha_vencimiento).format("YYYY-MM-DD") || moment(new Date()).format("YYYY-MM-DD"),
										description: cnd.description || "",
                                        projectName: prj.nombre,
									},
								]);
							});
						}
					});
				}
			});
		}
	}, [projects]);

	async function getProjects() {
		context.showLoading();
		const res = await req.post(URL_API + "/projects/getAll", {id_usuario: userId, id_client: 0});
		if (res) {
			if (res.proyectos) {
				setProjects(res.proyectos);
			}
		}
		context.closeLoading();
	}

	return (
		<div className="calendar row justify-center">
			<Menu />
			<div className="column justify-start">
				<Header />
				<div className="white-space-16"></div>
				<div className="calendar-content row justify-center">
					<div className="container">
						{permission !== 2 && <div className="column">
							<div>
								<FullCalendar defaultView="listWeek" plugins={[dayGridPlugin]} events={events} />
							</div>
							<div className="white-space-48"></div>
							<div className="information-box">
								<h3 className="weight-bold">Proyectos</h3>
								<div className="white-space-16"></div>
								<div className="calendar-table">
									<div className="table-responsive">
										<table>
											<thead>
												<tr className="text-left">
													<th>Proyecto</th>
													<th>Condición</th>
													<th className="text-center">Fecha de vencimiento</th>
												</tr>
											</thead>
											<tbody>
												{events.map((ev) => (
													<tr className="table-row text-left">
														<td>{ev.projectName}</td>
														<td>{ev.title}</td>
														<td className="text-center">{moment(ev.start).isValid() ? moment(ev.start).format("DD-MM-YYYY") : "Sin fecha"}</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>}
					</div>
				</div>
				<div className="white-space-48"></div>
			</div>
		</div>
	);
};

export default Consumer(CalendarView);
