import React, {Component} from "react";
import {Consumer} from "../../context";
import Load from "../../images/load.gif";

class LoadingOverlay extends Component {
	render() {
		return (
			<div className="image-zoom-modal align-center justify-center" id="loadingOverlay">
				<div className="modal-content-zoom column align-center">
					<div className="container justify-center align-center">
						<img id="img-zoom" className="img-zoom" src={Load} alt="imagen" />
					</div>
				</div>
			</div>
		);
	}
}

export default Consumer(LoadingOverlay);
