import React, {useState, useEffect} from "react";
import {URL_API} from "../../core/urlsApi";
import Request from "../../api";
import swal from "sweetalert";

const req = new Request();

const DocumentNav = ({id_proyecto, id_condicion, id_termino, terminos, load, closeNav, userId}) => {
	const [loadingSave, setLoadingSave] = useState(false);
	const [files, setFiles] = useState([]);

	useEffect(() => {
		setFiles([]);
	}, [id_condicion]);

	async function saveNewFiles(e) {
		e.preventDefault();
		if (files.length === 0) {
			swal("Error", "No se han agregado archivos", "error");
			return;
		}
		const data = {
			id_proyecto: id_proyecto,
			id_condicion: id_condicion,
			id_termino: id_termino,
			terminos: terminos,
		};
		saveCondition(data);
	}

	async function saveCondition(data) {
		setLoadingSave(true);
		const res = await req.postFile(URL_API + "/projects/addFiles", data, [...files]);
		console.log(res);
		if (res) {
			if (res.updated) {
				swal("Datos de condición actualizados.", {
					icon: "success",
				});
				closeNav();
				load(userId);
			} else {
				console.log(res);
			}
		}
		setLoadingSave(false);
		setFiles([]);
		closeNav();
	}

	const dragOverHandler = (ev) => {
		//// para prevenir que no se descargue el archivo
		ev.preventDefault();
	};

	const dropHandler = (ev) => {
		ev.preventDefault();
		if (ev.dataTransfer.items) {
			// en el caso que se pasen varios archivos
			for (let i = 0; i < ev.dataTransfer.items.length; i++) {
				// re revisa que sean archivos
				if (ev.dataTransfer.items[i].kind === "file") {
					let file = ev.dataTransfer.items[i].getAsFile();
					if (file.type !== "") {
						setFiles((f) => [...f, file]);
					}
				}
			}
		} else {
			// en el caso que solo sea un archivo
			for (let i = 0; i < ev.dataTransfer.files.length; i++) {
				let file = ev.dataTransfer.files[i].getAsFile();
				if (file.type !== "") {
					setFiles((f) => [...f, file]);
				}
			}
		}
	};

	const selectFiles=ev=>{
		setFiles(ev);
		//console.log(files);
	}

	return (
		<div id="documentNav" className="sidepanel">
			<div id="documentNav-content" className="sidepanel-content">
				<a className="closebtn" onClick={closeNav.bind(this)}>
					&times;
				</a>

				<div className="column align-center">
					<div className="header-title">
						<h4 className="weight-regular">Nuevos documentos</h4>
					</div>
					<div className="white-space-64"></div>

					<form id="drop-form" className="content-inputs column" onSubmit={saveNewFiles}>
						<div className="row">
							<div id="drop_zone" className="column" onDrop={dropHandler} onDragOver={dragOverHandler}>
								{
								files.length === 0 ? (
									<input type="file" multiple onChange={(ev)=>selectFiles(ev.target.files)} />
								) : (
									<>
										{
										//files.map((file, index) => (
										//	<p key={index}>{file.name}</p>
										//))
										//console.log(setFiles())
										}
																				
										
										<p >{files.length} archivo(s)</p>
										<div className="white-space-16"></div>

										<div
											className="justify-center cursor-pointer"
											onClick={() => {
												setFiles([]);
											}}
										>
											<i className="fas fa-eraser"></i>&nbsp; Limpiar
										</div>
									</>
								)}
							</div>
						</div>
						<div className="column">
							<button type="submit" className="btn btn-success">
								{loadingSave ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-save"></i>}&nbsp;
								Guardar
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default DocumentNav;
