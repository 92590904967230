import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import moment from "moment-timezone";
import swal from "sweetalert";
import {Consumer} from "../../context";
import {URL_API} from "../../core/urlsApi";
import Request from "../../api";
import Header from "../header";
import Menu from "../menu";
import ConditionNav from "./condition-nav";
import DocumentNav from "./document-nav";

const req = new Request();

const Conditions = (props) => {
	
	const [project, setProject] = useState([]);
	const [terminos, setTerminos] = useState([]);
	const [conditionEdit, setConditionEditState] = useState(false);
	const [condition, setCondition] = useState([]);
	const [term, setTerm] = useState([]);
	const [conditions, setConditions] = useState([]);
	const [documents, setDocuments] = useState([]);
	const [conditionDesc, setConditionDesc] = useState([]);
	const [permisosUser, setPermisosUser] = useState([]);
	const [userId, setUserId] = useState(0);
	const [permission, setPermission] = useState(0);

	useEffect(() => {
		const getUserId = async () => {
			const { data } = await props.context?.loadUser();
			if (data) {
				const { id_usuario, tipo } = data;
				if (id_usuario) {
					await setUserId(id_usuario);
					await setPermission(tipo);

					load(id_usuario);
				}
			}
		};
		getUserId();
	}, []);

	async function load(id) {
		let id_proyecto = props.match.params.id_prj;
		let id_termino = props.match.params.id_term;
		let id_condicion = props.match.params.id_condition;

		props.context.showLoading();
		if (id_proyecto) {
			const res = await req.get(`${URL_API}/projects/get/by/id/${id_proyecto}`);
			if (res) {
				if (res.proyecto) {
					//console.log(res.proyecto);
					const prjct = res.proyecto;

					setProject(prjct);

					if (prjct.terminos !== null && prjct.terminos !== "") {
						let terms = prjct.terminos;
						setTerminos(prjct.terminos);

						let termino = terms.filter((t) => t.id_termino == id_termino);
						if (termino.length !== 0) {
							setTerm(termino[0]);

							let condiciones = termino[0].condiciones;
							if (condiciones !== "" && condiciones !== null) {
								setConditions(condiciones);
								let condicion = condiciones.filter((c) => c.id_condicion == id_condicion);
								
								if (condicion.length !== 0) {
									let condDesc = [];
									condDesc = condicion[0].description.split(/\n\n/);
									//console.log(condDesc);
									setConditionDesc(condDesc);
									setCondition(condicion[0]);
									
									//console.log(condicion[0].documentos);
									setDocuments(condicion[0].documentos);
								}
							}
						}
					}
				}
			}

			const resUsers = await req.post(`${URL_API}/projects/getUserPermission/`, { id_proyecto: parseInt(id_proyecto), id_usuario: id });
			if (resUsers) {
				if (resUsers.usuarios) {
					console.log(resUsers.usuarios);
					setPermisosUser(resUsers.usuarios);
				}
			}
		}
		props.context.closeLoading();
	}

	function openNav(nameModal, nameContent) {
		setConditionEditState(true);
		const sidePanel = document.getElementById(nameModal);
		const sidePanelContent = document.getElementById(nameContent);
		if (sidePanel && sidePanelContent) {
			sidePanel.style.width = "100%";
			sidePanelContent.style.width = "600px";
		}
	}

	function closeNav(nameModal, nameContent) {
		setConditionEditState(false);
		const sidePanel = document.getElementById(nameModal);
		const sidePanelContent = document.getElementById(nameContent);
		if (sidePanel && sidePanelContent) {
			sidePanel.style.width = "0";
			sidePanelContent.style.width = "0";
		}
	}

	async function downloadDocument(doc) {
		let extension = doc.docName.split(".");
		extension = extension[1];
		//console.log('exten: '+extension);
		//console.log(URL_API);
		const res = await req.get(`${URL_API}/projects/get/file/${doc.token}/${extension}`, {});
		const {buffer} = res;
		//alert('url'+res.buffer);
		//console.log('re: '+buffer.data);
		
		if(extension=='pdf'){
			//alert('url'+buffer.data);
			window.open(res.buffer,"_blank");
		}else{
			
			if (buffer) {
				const bytes = new Uint8Array(buffer.data);
				const blob = new Blob([bytes]);
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
	
				link.download = doc.docName;
				link.click();
			} else {
				swal("Error", res.message || "No se pudo crear el documento de excel.");
			}
		}		
	}

	async function removeDocument(doc) {
		let terms = terminos.map((t) => {
			if (t.id_termino == term.id_termino) {
				t.condiciones.map((cond) => {
					if (cond.id_condicion == condition.id_condicion) {
						if (cond.documentos) {
							if (cond.documentos.length !== 0) {
								cond.documentos = cond.documentos.filter(d => d.token !== doc.token);
							}
						}
					}
				})
			}

			return t
		});

		const data = {
			insert: false,
			id_proyecto: project.id_proyecto,
			terminos: terms,
		};

		const res = await req.post(URL_API + "/projects/addTerm", data);
		if (res.updated) {
			swal("Documento eliminado.", {
				icon: "success",
			});

			load(userId);
		}
	}

	return (
		<div className="projects row justify-center">
			<Menu />
			<div className="column justify-start">
				<Header />
				<div className="projects-content justify-center">
					<div className="container column">
						<div className="terms-gral">
							<h2>{project.nombre}</h2>
							<div className="white-space-16"></div>
							<h5 className="weight-regular">{project.descripcion}</h5>
							<div className="white-space-16"></div>
							<div className="conditions">
								<div className="screen row align-center">
									<Link to={`/projects/terms/${project.id_proyecto}`}>
										<h4 className="weight-regular">{term.nombre}</h4>
									</Link>
									<i className="fas fa-chevron-right space-8 font-mini"></i>
									<h4 className="weight-medium space-8 color-primary">{condition.nombre}</h4>
									{permission !== 2 && <i
										className="fas fa-pen space-8 cursor-pointer"
										onClick={() => {
											openNav("conditionNav", "conditionNav-content");
										}}
									></i>}
									<ConditionNav
										edit={true}
										project={project}
										terms={terminos}
										id_termino={term.id_termino}
										condition={condition}
										conditions={conditions}
										closeConditionNav={() => {
											closeNav("conditionNav", "conditionNav-content");
										}}
										load={load}
									/>
								</div>
								<div className="white-space-16"></div>
								<div className="content column">
									<div className="column">
										{conditionDesc.map(desc => {
											return <React.Fragment>
												<h5 className="weight-regular text-justify">{desc}</h5>
												<br />
											</React.Fragment>
										})}
										
									</div>
									<div className="white-space-16"></div>
									<div className="row">
										<div className="column">
											<div className="row">
												<div className="column">
													<h5>Documentos</h5>
												</div>
												{permission !== 2 &&
												<div className="column align-end">
													<i
														className="fas fa-plus-square color-green cursor-pointer"
														onClick={() => {
															openNav("documentNav", "documentNav-content");
														}}
													></i>
												</div>}
											</div>
											<div className="white-space-8"></div>
											<div className="documents-table table-responsive">
												<table>
													<thead>
														<tr className="text-left">
															<th>Nombre</th>
															{permisosUser.descargas === 1 && 
																<th className="text-center">Descargar</th>}
															{permisosUser.descargas === 1 && permission !== 2 && 
																<th className="text-center">Eliminar</th>}
														</tr>
													</thead>
													<tbody>
														{documents.length === 0 ? (
															<tr className="table-row text-center">
																<td colSpan="3" className="text-center">
																	Sin documentos
																</td>
															</tr>
														) : (
															documents.map((document) => {
																return (
																	<tr key={document.docName} className="table-row text-left">
																		<td>{document.docName}</td>
																		{permisosUser.descargas === 1 && <td className="text-center">
																			<i
																				className="fas fa-download cursor-pointer"
																				onClick={() => {
																					downloadDocument(document);
																				}}
																			></i>
																		</td>}
																		{}
																		{permisosUser.descargas === 1 && permission !== 2 && <td className="text-center">
																			<i className="fas fa-trash cursor-pointer" onClick={() =>
																			removeDocument(document)} /></td>}
																	</tr>
																);
															})
														)}
													</tbody>
												</table>
											</div>
										</div>

										<div className="column">
											<div className="row justfiy-end">
												<div className="column">
													<h5 className="text-center">Fecha vencimiento</h5>
													<div className="white-space-8"></div>
													<h5 className="weight-regular text-center">
														{moment(condition.fecha_vencimiento).format("DD/MM/YYYY")}
													</h5>
												</div>
												<div className="column">
													<h5 className="text-center">Estatus</h5>
													<div className="white-space-8"></div>
													<h5 className="weight-regular text-center">{condition.status}</h5>
												</div>
											</div>
											<div className="white-space-48"></div>
											<div className="row">
												<div className="column">
													<h5 className="text-center">Recordatorio</h5>
													<div className="white-space-8"></div>
													<h5 className="weight-regular text-center">
														{condition.tiempo_recordatorio} -{" "}
														{condition.plazo_recordatorio == "days"
															? "Días antes"
															: condition.plazo_recordatorio == "weeks"
															? "Semanas antes"
															: condition.plazo_recordatorio == "months"
															? "Meses antes"
															: condition.plazo_recordatorio == "years"
															? "Años antes"
															: null}
													</h5>
												</div>
											</div>
										</div>
									</div>
									<div className="white-space-32"></div>
								</div>
								<DocumentNav
									id_proyecto={project.id_proyecto}
									id_termino={term.id_termino}
									id_condicion={condition.id_condicion}
									terminos={terminos}
									conditions={conditions}
									load={load}
									userId={userId}
									closeNav={() => {
										closeNav("documentNav", "documentNav-content");
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Consumer(Conditions);
