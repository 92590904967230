/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 4
 */

import React, {Component} from "react";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";

// Conexión con el Context API
import {GlobalContext} from "../context";
// Páginas Web
import Login from "../pages/Login";
import Clients from "../pages/Clients";
import Calendar from "../pages/Calendar";
import Users from "../pages/Users";
import Projects from "../pages/Projects";
import Terms from "../components/projects/terms-gral";
import Conditions from "../components/conditions.jsx/conditions";
import LoadingOverlay from "../components/modals/loadingOverlay";


const PrivateRoute = ({component: Component, ...rest}) => (
	<Route
		{...rest}
		render={(props) => {
			let usuario = window.localStorage.getItem("bdusr");
			if (usuario) {
				usuario = JSON.parse(usuario);
				if (usuario.auth) {
					return <Component {...props} />;
				} else {
					return <Redirect to={{pathname: "/login", state: {from: props.location}}} />;
				}
			} else {
				return <Redirect to={{pathname: "/login", state: {from: props.location}}} />;
			}
		}}
	/>
);

class Routes extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {}

	renderRoutes() {
		// RUTAS PRIVADAS
		let routes = [{path: "/", component: Login, private: true}];
		let routesRender = [];

		routesRender = routes.map((route, index) => {
			return <PrivateRoute key={index} exact path={route.path} component={route.component} />;
		});
		routesRender.push(<Route exact path={"/login"} component={Login} key={routes.length + 1} />);
		routesRender.push(<Route exact path={"/calendar"} component={Calendar} key={routes.length + 1} />);
		routesRender.push(<Route exact path={"/clients"} component={Clients} key={routes.length + 1} />);
		routesRender.push(<Route exact path={"/users"} component={Users} key={routes.length + 1} />);
		routesRender.push(<Route exact path={"/projects/:id_client"} component={Projects} key={routes.length + 1} />);
		routesRender.push(<Route exact path={"/projects/terms/:id_term"} component={Terms} key={routes.length + 1} />);
		routesRender.push(<Route exact path={"/projects/:id_prj/:id_term/:id_condition"} component={Conditions} key={routes.length + 1} />);

		return routesRender;
	}

	render() {
		return (
			<GlobalContext>
				<BrowserRouter>
					<div className="flex main">
						<LoadingOverlay> </LoadingOverlay>
						<div className="column full">
							<Switch>{this.renderRoutes()}</Switch>
						</div>
					</div>
				</BrowserRouter>
			</GlobalContext>
		);
	}
}

export default Routes;
