import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Logo from "../images/PAS-logo-white.png";
import { Consumer } from "../context";

const Menu = ({context}) => {
	const [rol, setUserRol] = useState(0);

	useEffect(() => {
		let user = window.localStorage.getItem('psusr');
		if (user) {
			user = JSON.parse(user);

			if (user.data) {
				if (user.auth) {
					console.log(user.data.tipo);
					setUserRol(user.data.tipo);
				}
			}
		}
	}, []);

	return (
		<div className="menu">
			<div className="menu-element column align-center">
				<div className="logo align-center">
					<img src={Logo} alt="" />
				</div>
				{rol !== 2 && 
					<React.Fragment>
						<Link className="menu-element-name column align-center" to={"/calendar"}>
							<i className="far fa-calendar-alt"></i>
							<div className="white-space-8"></div>
							Calendario
						</Link>
						<Link className="menu-element-name column align-center" to={"/clients"}>
							<i className="fas fa-user-friends"></i>
							<div className="white-space-8"></div>
							Clientes
						</Link>
					</React.Fragment>
				}
				<Link className="menu-element-name column align-center" to={"/projects/0"}>
					<i className="far fa-folder-open"></i>
					<div className="white-space-8"></div>
					Proyectos
				</Link>
				{rol === 1 && <Link className="menu-element-name column align-center" to={"/users"}>
					<i className="fas fa-users"></i>
					<div className="white-space-8"></div>
					Usuarios
				</Link>}
			</div>
		</div>
	);
};

export default Consumer(Menu);
