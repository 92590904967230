import request from "superagent";

class Request {
	get(url, data) {
		const result = request
			.get(url)
			.query(data)
			.then((res) => {
				//// console.log(res.body);
				return res.body;
			})
			.catch((err) => {
				//// console.log(err.message);
				return {error: true, message: err.message};
			});

		return result;
	}

	post(url, data) {
		const result = request
			.post(url)
			.send(data)
			.then((res) => {
				//// console.log(res.body);
				return res.body;
			})
			.catch((err) => {
				//// console.log(err.message);
				return {error: true, message: err.message};
			});

		return result;
	}

	postToUrl(url, data) {
		const result = request
			.post(url)
			.send(data)
			.then((res) => {
				return res.body;
			})
			.catch((err) => {
				// console.log(err.message);
				return {error: true, message: err.message};
			});
		return result;
	}

	async postFile(url, data, files) {
		const response = await new Promise((res) => {
			const postRequest = request.post(url);

			if (Array.isArray(files)) {
				files.forEach((file, index) => {
					if (file) {
						postRequest.attach(`file`, file);
					}
				});
			}
			if (data) {
				const keys = Object.keys(data);
				keys.forEach((key) => {
					if (data[key] !== undefined) {
						postRequest.field(key, JSON.stringify(data[key]));
					}
				});
			}

			postRequest
				.set("Accept", "application/json")
				.then((resp) => {
					res(resp.body || {error: true, message: "error indefinido"});
				})
				.catch((error) => {
					res(error);
				});
		});

		return response;
	}
}

export default Request;
