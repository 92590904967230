import React, {useState, useEffect} from "react";
import {URL_API} from "../../core/urlsApi";
import Request from "../../api";
import swal from "sweetalert";

const req = new Request();

const ClientsNav = ({client, closeConditionNav, getClients}) => {
	const [name, setClientName] = useState(client.nombre);
	const [email, setClientEmail] = useState(client.correo);
	const [phone, setClientPhone] = useState(client.telefono);
	const [city, setClientCity] = useState(client.ciudad);
	const [loadingSave, setLoadingSave] = useState(false);

	useEffect(() => {
		if (client.length !== 0) {
			setClientName(client.nombre);
			setClientEmail(client.correo);
			setClientPhone(client.telefono);
			setClientCity(client.ciudad);
		} else {
			colocaValor("nombre", "");
			colocaValor("correo", "");
			//colocaValor('ciudad', '');
			colocaValor("phone", "");
		}
	}, [client]);

	async function colocaValor(id, valor) {
		const inputs = document.getElementsByName(id);
		if (inputs) {
			inputs.forEach((input) => {
				input.value = valor;
			});
		}
	}

	function newClient(e) {
		e.preventDefault();
		setLoadingSave(true);

		const form = e.target;

		const data = {
			id_cliente: 0,
			nombre: form.nombre.value,
			correo: form.correo.value,
			ciudad: form.ciudad.value,
			telefono: form.phone.value,
		};

		saveClient(data);
	}

	function updClient() {
		setLoadingSave(true);
		const data = {
			id_cliente: client.id_client,
			nombre: name,
			correo: email,
			ciudad: city,
			telefono: phone,
		};

		saveClient(data);
	}

	async function saveClient(data) {
		const res = await req.post(URL_API + "/clients/add", data);
		if (res) {
			if (res.inserted || res.updated) {
				swal(res.message, {
					icon: "success",
				});
				getClients();
				closeConditionNav();
			}
		}

		setLoadingSave(false);
	}

	return (
		<div id="clientsNav" className="sidepanel">
			<div id="clientsNav-content" className="sidepanel-content">
				<a className="closebtn" onClick={closeConditionNav}>
					&times;
				</a>
				{client.length !== 0 ? (
					<div className="column align-center">
						<div className="header-title">
							<h4 className="weight-regular">Editar cliente</h4>
						</div>
						<div className="content-inputs column">
							<div className="column">
								<label>Nombre</label>
								<div className="white-space-8"></div>
								<input 
									className="input" 
									type="text" 
									value={name} 
									onChange={(e) => setClientName(e.target.value)}
									required />
							</div>
							<div className="white-space-16"></div>
							<div className="column">
								<label>Correo electrónico</label>
								<div className="white-space-8"></div>
								<input 
									className="input" 
									type="email" 
									value={email} 
									onChange={(e) => setClientEmail(e.target.value)}
									required />
							</div>
							<div className="white-space-16"></div>
							<div className="row">
								<div className="column">
									<label>Ciudad</label>
									<div className="white-space-8"></div>
									<input 
										className="input" 
										type="text" 
										value={city} 
										onChange={(e) => setClientCity(e.target.value)}
										required />
									{/*<select className="input" value={city} onChange={(e) => setClientCity(e.target.value)} id="ciudades">
										<option value="Cancún">Cancún</option>
										<option value="Tulum">Tulum</option>
									</select>*/}
								</div>
								<div className="column space-16">
									<label>Teléfono</label>
									<div className="white-space-8"></div>
									<input
										className="input"
										type="text"
										maxLength="18"
										value={phone}
										onChange={(e) => setClientPhone(e.target.value)}
										required
									/>
								</div>
							</div>
							<div className="white-space-24"></div>
							<div className="column">
								<button type="button" className="btn btn-success" onClick={updClient}>
									{loadingSave ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-save"></i>}&nbsp; Guardar
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className="column align-center">
						<div className="header-title">
							<h4 className="weight-regular">Nuevo cliente</h4>
						</div>
						<form className="content-inputs column" onSubmit={newClient}>
							<div className="column">
								<label>Nombre</label>
								<div className="white-space-8"></div>
								<input className="input" name="nombre" type="text" />
							</div>
							<div className="white-space-16"></div>
							<div className="column">
								<label>Correo electrónico</label>
								<div className="white-space-8"></div>
								<input className="input" name="correo" type="email" />
							</div>
							<div className="white-space-16"></div>
							<div className="row">
								<div className="column">
									<label>Ciudad</label>
									<div className="white-space-8"></div>
									<input className="input" name="ciudad" type="text" />
									{/*<select className="input" name="ciudad" id="ciudades">
										<option value="Cancún">Cancún</option>
										<option value="Tulum">Tulum</option>
									</select>*/}
								</div>
								<div className="column space-16">
									<label>Teléfono</label>
									<div className="white-space-8"></div>
									<input className="input" name="phone" type="text" maxLength="18" />
								</div>
							</div>
							<div className="white-space-24"></div>
							<div className="column">
								<button type="submit" className="btn btn-success">
									{loadingSave ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-save"></i>}&nbsp; Guardar
								</button>
							</div>
						</form>
					</div>
				)}
			</div>
		</div>
	);
};

export default ClientsNav;
